import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {shortTokenLink, varTime, dexscreenerLink, formatTimestamp, pumpLink, TokenType, get_user_flow, tokencheckLink, dexbullBot, extLink} from '../components/Helper';
import { useCompleteList, useDexpaidList, useWalletTokenList } from 'src/components/Utils';
import { API_DEXBULL_BASE, API_NEUTRON_BASE } from 'src/config';


const PairN : React.FC = () => {

    const pairURL = "wss://ws.neutron.ag/pairs";
    // const pairURL = "ws://localhost:9443/pairs";

    const refs = {
      complete: useRef(true), //
      walletToken: useRef(true), //
      dexbull: useRef(true),
      dexpaid : useRef(true),
  };

  const [dataList, setDataList] = useState<PairN[]>([]);
  
  const completeList = useCompleteList(dataList, refs);
  const walletTokenList = useWalletTokenList(dataList, refs);
  const dexpaidList = useDexpaidList(dataList, refs);


  //#region 
  const [dexbullList, setDexbullList] = useState<Pair[]>([]);
  useEffect(() => {
    if(refs.dexbull.current === true){
      fetch("https://api.dexbull.com/pools/solana")
        .then(response => {
          return response.json(); 
        })
        .then(data => {
          setDexbullList(data);
          refs.dexbull.current = false; // Reset cờ sau khi xử lý xong
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    }

  },[dataList]);
  //#endregion


   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: PairN[] = message.data.map((item: any) => ({
                    mint : item.mint,
                    poolId: item.poolId,
                    timestamp: Number(item.timestamp),
                    delay : Number(item.delay),
                    isNew : false,
                }));

                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {

                if(message.data.length > 0) {
                  refs.complete.current = true;
                  refs.walletToken.current = true;
                  refs.dexbull.current = true;
                  refs.dexpaid.current = true;
                }

                const formattedPair: PairN[] = message.data.map((item: any) => ({
                    mint : item.mint,
                    poolId: item.poolId,
                    timestamp: Number(item.timestamp),
                    delay : Number(item.delay),
                    isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //10s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);


   /*-------------- BUY POOL --------------*/

   const [clickedButtons, setClickedButtons] = useState({});
   const [clickedButtons_sell, setClickedButtons_sell] = useState({});

   const [tooltipVisible, setTooltipVisible] = useState(false);

   const [result, setResult] = useState<SwapResult | null>();

    const handleBuyToken = async(poolId) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        setResult(null);

        fetch(API_NEUTRON_BASE + '/buy/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);

          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }


    const handleSellToken = async(poolId) => {
        setClickedButtons_sell((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        setResult(null);

        fetch(API_NEUTRON_BASE + '/sell/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons_sell({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);

          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }

    
    return (
      <><Header/>
        <div className="mx-auto p-2">
            
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr className="uppercase text-green-300">
                        <th className="border border-gray-600 px-2 py-1">N</th>
                        <th className="border border-gray-600 px-2 py-1">Time</th>
                        <th className="border border-gray-600 px-2 py-1">Delay</th>
                        <th className="border border-gray-600 px-2 py-1">PAID</th>

                        <th className="border border-gray-600 px-2 py-1">Mint</th>
                        <th className="border border-gray-600 px-2 py-1">Pool</th>

                        <th className="border border-gray-600 px-2 py-1">Dex</th>
                        <th className="border border-gray-600 px-2 py-1">Action</th>
                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {
                      const check_dexpaid = dexpaidList?.find(p => p.tokenAddress === item.mint);

                      const check_complete = completeList.find(p => p.mint === item.mint );
                      const user_address = check_complete?check_complete.user: null;
                      const check_walletToken = walletTokenList.find(p => p.mint === item.mint );

                      const check_dexbull_pair = dexbullList.find(p => p.mint === item.mint );
                      let time_DN = 0;
                      if(check_dexbull_pair){
                          time_DN = (Date.parse(check_dexbull_pair.createdAt) - item.timestamp)/1000;
                      }

                      const link_token_by_mint = `${API_DEXBULL_BASE}/token/${item.mint}`;
                      const link_cap = 'https://api.neutron.ag/mint/cap/' + item.mint;
                      const link_pool_by_mint = 'https://api.neutron.ag/mint/pool/' + item.mint;

                      return (

                        <tbody>
                          <tr key={item.mint} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            <td className='px-2 py-1 text-gray-800'>{index+1}</td>
                            <td className='px-2 py-1'>{varTime(item.timestamp /1000)}
                                <span className='float-right text-gray-400 p-1'>{time_DN?'⚡' + time_DN.toFixed(0) + 's': '-'}</span>
                            </td>
                           
                            <td className='px-2 py-1'>{formatTimestamp(item.delay)}</td>

                            <td className='px-2 py-1'>{check_dexpaid?(<div className='flex'><img width="24" height="24" src={check_dexpaid.icon}/> <span className='ml-2 text-sm'>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"} </td>

                            <td className='px-2 py-1'>{shortTokenLink(item.mint, TokenType.TOKEN)}
                                <a target="_blank" href={link_token_by_mint}><span className='float-right p-2'>→</span></a>
                            </td>
                            <td className='px-2 py-1'>{shortTokenLink(item.poolId, TokenType.ACCOUNT)}
                                <a target="_blank" href={link_pool_by_mint}><span className='float-right p-2'>→</span></a>
                            </td>

                            <td className='px-2 py-1 underline space-x-2'>
                                {extLink(item.mint)} 
                                {user_address?(shortTokenLink(user_address, TokenType.ACCOUNT)):''}
                                {get_user_flow(item.mint, user_address)}

                            </td>

                            <td className='px-2 py-1 underline space-x-2'>
                                <button onClick={() => handleBuyToken(item.poolId)} className='bg-gray-500 px-2'>
                                  {clickedButtons[item.poolId] ? 'BUY...' : 'BUY'}
                                </button>

                                {
                                  check_walletToken?(<button onClick={() => handleSellToken(item.poolId)} className='bg-gray-500 px-2'>
                                    {clickedButtons_sell[item.poolId] ? 'SELL...' : 'SELL'}
                                  </button>):''
                                }

                                  <a target="_blank" href={link_cap}><span className='float-right p-2'>→</span></a>

                            </td>

        
                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>
           
        </div>
      </>
    )
  
}
  
export default PairN;