import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {shortTokenLink, varTime, dexscreenerLink, formatTimestamp, TokenType, get_user_flow, tokencheckLink, dexbullBot, extLink} from '../components/Helper';
import { useCompleteList, useCountList } from 'src/components/Utils';

import { Box, Button, Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import { API_DSOL_BASE, API_NEUTRON_BASE } from 'src/config';


const DexPaid : React.FC = () => {
  const refs = {
      complete: useRef(true),
  };
   const wsURL = "wss://ws-paid.dexbull.com/dexpaid";
    // const wsURL = "ws://localhost:9443/pairs";

   const [dataList, setDataList] = useState<DexpaidOrder[]>([]);
   const completeList = useCompleteList(dataList, refs);

   const countList = useCountList("https://api-paid.dexbull.com/day/solana?limit=100");

   useEffect(() => {
      fetch("https://api-paid.dexbull.com/chain/solana?limit=20")
          .then(response => {
            return response.json(); 
          })
          .then(data => {
              setDataList(data);
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
  },[dataList]);

    /*
   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(wsURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: Order[] = message.data.map((item: any) => ({
                    icon: item.icon,
                    header: item.header,
                    paymentTimestamp: item.paymentTimestamp,
                    tokenAddress : item.tokenAddress,
                    createdAt: item.createdAt,
                   
                    isNew : false,
                }));

                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                const formattedPair: Order[] = message.data.map((item: any) => ({
                    icon: item.icon,
                    header: item.header,
                    paymentTimestamp: item.paymentTimestamp,
                    tokenAddress : item.tokenAddress,
                    createdAt: item.createdAt,

                    isNew : true,
                }));

                console.log('formattedPair', formattedPair);

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //10s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);
    */


   /*-------------- BUY  MINT --------------*/

   const [clickedButtons, setClickedButtons] = useState({});

    const handleBuyToken = async(mint) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [mint]: !prevState[mint], // Đảo trạng thái khi nút được bấm
        }));

        //alert(`Button clicked for item with id: ${poolId}`);
        fetch(API_NEUTRON_BASE + '/buy/mint/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: mint}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }

   return (
      <><Header/>
       <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} key="1">
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <TableContainer component={Paper} sx={{ overflowX: "auto", maxWidth: "100%" }}>
                            <Table>
                              <TableHead>
                                    <TableRow sx={{textTransform: "uppercase"}}>
                                        <TableCell><AccessTimeIcon/></TableCell>
                                        <TableCell>Delay</TableCell>
                                        
                                        <TableCell>Mint</TableCell>
                                        <TableCell>Icon</TableCell>
                                        <TableCell>Header</TableCell>
                                        
                                        <TableCell>Owner</TableCell>
                                        <TableCell>LINK</TableCell>
                                        <TableCell>API</TableCell>
                                        <TableCell>Action</TableCell>

                     

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                  {dataList.map((item : any, index : number) => {
                                      const check_complete = completeList.find(p => p.mint === item.tokenAddress );
                                      const user_address = check_complete?check_complete.user: null;
                                      const link_cap = `${API_DSOL_BASE}/mint/cap/${item.tokenAddress}`;

                                    return (
                                      <TableRow key={item.mint} sx={{
                                        backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                                        transition: "background-color 0.3s ease",
                                      }}>

                                          <TableCell>{varTime(item.paymentTimestamp / 1000)}</TableCell>
                                          <TableCell>{ formatTimestamp((Date.parse(item.createdAt) - item.paymentTimestamp)/1000)}</TableCell>
                                          <TableCell>{shortTokenLink(item.tokenAddress, TokenType.TOKEN)}</TableCell>
                                          <TableCell><img src={item.icon} className="h-8 w-8"/></TableCell>
                                          <TableCell><img src={item.header} className="h-8"/></TableCell>
                                          
                                          
                                          <TableCell>
                                            <Box gap={2} display="flex">
                                              {shortTokenLink(user_address, TokenType.ACCOUNT)}

                                              {get_user_flow(item.tokenAddress, user_address)}

                                            </Box>
                                          </TableCell>

                                          <TableCell>
                                              {extLink(item.tokenAddress)}
                                          </TableCell>

                                          <TableCell>
                                              <a target="_blank" className="text-orange-500"  href={link_cap}>CAP</a>
                                          </TableCell>
                                          <TableCell>
                                            <Button variant="outlined" onClick={() => handleBuyToken(item.tokenAddress)} className='bg-gray-500 px-2'>
                                              {clickedButtons[item.tokenAddress] ? 'BUY...' : 'BUY'}
                                            </Button>
                                        </TableCell>
                                          

                                      </TableRow>
                                    )})
                                  }

                                  </TableBody>
                                </Table>
                          </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        {/* <div className="mx-auto p-2">
            
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr className="uppercase">
                        <th className="border border-gray-600 px-2 py-1">Time</th>
                        <th className="border border-gray-600 px-2 py-1">Delay</th>

                        <th className="border border-gray-600 px-2 py-1">Mint</th>
                        <th className="border border-gray-600 px-2 py-1">Icon</th>
                        <th className="border border-gray-600 px-2 py-1">Header</th>

                        <th className="border border-gray-600 px-2 py-1">Dex</th>
                        <th className="border border-gray-600 px-2 py-1">Owner</th>

                        <th className="border border-gray-600 px-2 py-1">Action</th>

                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {
                      const check_complete = completeList.find(p => p.mint === item.tokenAddress );
                      const user_address = check_complete?check_complete.user: null;
                      const link_cap = 'https://api-sol.dexbull.com/mint/cap/' + item.tokenAddress;
                      return (

                        <tbody>
                          <tr key={item.tokenAddress} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            <td className='px-2 py-1'>{varTime(item.paymentTimestamp / 1000)}</td>
                            <td className='px-2 py-1 text-gray-600'>{ formatTimestamp((Date.parse(item.createdAt) - item.paymentTimestamp)/1000)}</td>

                            <td className='px-2 py-1'>{shortTokenLink(item.tokenAddress, TokenType.TOKEN)}</td>
                            <td className='px-2 py-1'><img src={item.icon} className="h-8 w-8"/></td>
                            <td className='px-2 py-1'><img src={item.header} className="h-8"/></td>
                         
                            <td className='px-2 py-1 underline space-x-2'>
                                  {extLink(item.tokenAddress)}
                                
                            </td>
                            <td className='px-2 py-1 underline space-x-2'>
                                {user_address?(shortTokenLink(user_address, TokenType.ACCOUNT)):''}
                                {get_user_flow(item.tokenAddress, user_address)}
                            </td>

                            <td className='px-2 py-1 underline space-x-2'>
                                <button onClick={() => handleBuyToken(item.tokenAddress)} className='bg-gray-500 px-2'>
                                  {clickedButtons[item.tokenAddress] ? 'BUY...' : 'BUY'}
                                </button>
                                <a target="_blank" href={link_cap}><span className='float-right p-2'>→</span></a>
                            </td>

                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>

           
           
        </div> */}

        <Box sx={{ p: 0 }}>
                <Card sx={{ p: 2, width: "100%", maxWidth: "100%" }}>
                    <CardContent>
                        <Typography variant="h6">By Day</Typography>
                        <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={countList}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="_id" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
                        </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>   
          </Box>

      </>
    )
  
}
  
export default DexPaid;