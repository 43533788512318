
import { useState, useEffect } from 'react';
import { API_NEUTRON_BASE, API_SOL_BASE } from 'src/config';


export const useBalanceList = (dataList : any[], refs : any) => {
    const [balanceList, setBalanceList] = useState<Balance[]>([]);

    useEffect(() => {
        const fetchBalances = async () => {
            // Chuyển đổi dữ liệu thành list baseVault

            let list = dataList?.map(item => getBaseVault(item.ammKeys));
            list = list.filter(item => item !== null);

            const batchSize = 5; // Chia mỗi batch thành 5 phần tử
            const batchRequests = [] as any[];

            // Chia danh sách thành các batch nhỏ
            for (let i = 0; i < list.length; i += batchSize) {
                const batch = list.slice(i, i + batchSize);
                batchRequests.push(batch);
            }

            if (refs.balance.current === true && list.length > 0) {
                const fetchPromises = batchRequests.map(batch => 
                    fetch(`${API_SOL_BASE}/balance/bulk/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(batch),
                    })
                    .then(response => response.json())
                    .then(data => data)
                    .catch(error => {
                        console.log('Fetch Error:', error);
                    })
                );

                // Chờ tất cả các request hoàn thành
                Promise.all(fetchPromises)
                    .then(results => {
                        // Gộp tất cả dữ liệu lại và cập nhật balanceList
                        const allBalances = results.flat();
                        setBalanceList(allBalances);
                        refs.balance.current = false;
                    })
                    .catch(error => {
                        console.log('Error processing all batches:', error);
                    });
            }
        };

        fetchBalances();
    }, [dataList, refs.balance]);

    return balanceList;
};

export const useBalanceList2 = (dataList : any[], refs : any, addressField : string ) => {
    const [balanceList, setBalanceList] = useState<Balance[]>([]);

    useEffect(() => {
        const fetchBalances = async () => {
            // Chuyển đổi dữ liệu thành list baseVault
            let list = dataList?.map(item => item[addressField]);
            list = list.filter(item => item !== null);

            const batchSize = 5; // Chia mỗi batch thành 5 phần tử
            const batchRequests = [] as any[];

            // Chia danh sách thành các batch nhỏ
            for (let i = 0; i < list.length; i += batchSize) {
                const batch = list.slice(i, i + batchSize);
                batchRequests.push(batch);
            }

            if (refs.balance.current === true && list.length > 0) {
                const fetchPromises = batchRequests.map(batch => 
                    fetch(API_NEUTRON_BASE + '/balance/bulk/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(batch),
                    })
                    .then(response => response.json())
                    .then(data => data)
                    .catch(error => {
                        console.log('Fetch Error:', error);
                    })
                );

                // Chờ tất cả các request hoàn thành
                Promise.all(fetchPromises)
                    .then(results => {
                        // Gộp tất cả dữ liệu lại và cập nhật balanceList
                        const allBalances = results.flat();
                        setBalanceList(allBalances);
                        refs.balance.current = false;
                    })
                    .catch(error => {
                        console.log('Error processing all batches:', error);
                    });
            }
        };

        fetchBalances();
    }, [dataList, refs.balance]);

    return balanceList;
};

export const useDexpaidList = (dataList : any[], refs : any) => {
    const [dexpaidList, setDexpaidList] = useState<DexpaidOrder[]>([]);
    useEffect(() => {
       if(refs.dexpaid.current === true){
         fetch('https://api-paid.dexbull.com/chain/solana?limit=50')
           .then(response => {
               return response.json(); 
           })
           .then(data => {
               setDexpaidList(data);
               refs.dexpaid.current = false; // Reset cờ sau khi xử lý xong
           })
           .catch(error => {
               console.log('Fetch Error:', error);
           });
       }
    },[dataList, refs.dexpaid]);

    return dexpaidList;
};

export const useWalletTokenList = (dataList : any[], refs : any) => {
    const [walletTokenList, setWalletTokenList] = useState<WalletToken[]>([]);
    useEffect(() => {
     if(refs.walletToken.current === true){
       fetch(API_NEUTRON_BASE + "/wallet/token-list")
         .then(response => {
           return response.json(); 
         })
         .then(data => {
           setWalletTokenList(data);
           refs.walletToken.current = false; // Reset cờ sau khi xử lý xong
         })
         .catch(error => {
             console.log('Fetch Error:', error);
         });
     }
   },[dataList, refs.walletToken]);

   return walletTokenList;
}

export const useCompleteList = (dataList : any[], refs : any) => {
   const [pumpList, setPumpList] = useState<TokenComplete[]>([]);
   useEffect(() => {
      if(refs.complete.current === true){
        fetch(API_NEUTRON_BASE + "/pump/type-2/complete")
          .then(response => {
            return response.json(); 
          })
          .then(data => {
              setPumpList(data);
              refs.complete.current = false; // Reset cờ sau khi xử lý xong
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
      }
   },[dataList, refs.complete]);

   return pumpList;
}
   

//VD: https://api.dexbull.com/pair-day/solana?limit=30


export const useCountList = (url : string ) => {
    const [countList, setCountList] = useState<CountNumber[]>([]);

   useEffect(() => {
     fetch(url) 
       .then(response => {
         return response.json(); 
       })
       .then(data => {
            setCountList(data);
       })
       .catch(error => {
           console.log('Fetch Error:', error);
       });
 
   }, []);
   return countList;
}
   

export const initPool = (type : string, initLiquidity : any, ammKeys : any) => {
    //type = base or quote
    if(!ammKeys || !initLiquidity) return 0;
    const order = ammKeys.baseMint === 'So11111111111111111111111111111111111111112' ? true : false;
    const baseVault = order ?  ammKeys.baseVault : ammKeys.quoteVault;
    const quoteVault = order ?  ammKeys.quoteVault : ammKeys.baseVault;
    const quoteDecimals = order ?  ammKeys.quoteDecimals : ammKeys.baseDecimals;

    let lamport_count = order?initLiquidity.init_coin_amount:initLiquidity.init_pc_amount;
    let token_count = !order?initLiquidity.init_coin_amount:initLiquidity.init_pc_amount;
    if(type === "sol") return lamport_count / 10 ** 9;
    if(type === "token") return token_count  / 10 ** quoteDecimals;

    return 0;
}

export const getBaseVault = (ammKeys : any) => {
    if(!ammKeys) return null;
    const order = ammKeys.baseMint === 'So11111111111111111111111111111111111111112' ? true : false;
    const baseVault = order ?  ammKeys.baseVault : ammKeys.quoteVault;
    return baseVault;
}