import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import { shortTokenLink, varTime, solNumber, get_user_flow, TokenType, formatTimestamp, solCap, ratioChange, tokencheckLink, dexbullBot, extLink} from '../components/Helper';
import { getBaseVault, useBalanceList, useCompleteList, useCountList, useDexpaidList, useWalletTokenList } from 'src/components/Utils';
import { API_DEXBULL_BASE, API_DSOL_BASE, API_NEUTRON_BASE } from 'src/config';
import { Box, Button, Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";

const PairD : React.FC = () => {
  const refs = {
      complete: useRef(true),
      walletToken: useRef(true),
      balance: useRef(true),
      dexpaid : useRef(true),
  };

  const [dataList, setDataList] = useState<Pair[]>([]);

  const pairURL = "wss://ws.dexbull.com/pools/solana"; 
  // const pairURL = "ws://localhost:9443/pairs";

  // const countList = useCountList(`${API_DEXBULL_BASE}/count/day/solana_pools?limit=100`);
  const completeList = useCompleteList(dataList, refs);
  const walletTokenList = useWalletTokenList(dataList, refs);
  const balanceList = useBalanceList(dataList, refs);
  const dexpaidList = useDexpaidList(dataList, refs);
 

   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);
      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;
            

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: Pair[] = message.data.map((item: any) => ({
                    
                    mint : item.mint,
                    poolId: item.poolId,
                    creation_ts: item.creation_ts,
                    createdAt : item.createdAt,
                    txId : item.initLiquidity?.txId,
                    
                    ammKeys : item.ammKeys,
                    init_coin_amount : item.order?item?.initLiquidity.init_coin_amount : item?.initLiquidity.init_pc_amount,
                    init_pc_amount : item.order?item?.initLiquidity.init_pc_amount : item?.initLiquidity.init_coin_amount,

                    isNew : false,
                }));

                console.log('initial', formattedPair.length);
                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                if(message.data.length > 0) {
              
                  refs.complete.current = true;
                  refs.walletToken.current = true;
                  refs.balance.current = true;
                  refs.dexpaid.current = true;
                }


                const formattedPair: Pair[] = message.data.map((item: any) => ({
                    
                    mint : item.mint,
                    poolId: item.poolId,
                    creation_ts: item.creation_ts,
                    createdAt : item.createdAt,
                    txId : item.txId,
                    ammKeys : item.ammKeys,
                    init_coin_amount : item.order?item?.initLiquidity.init_coin_amount : item?.initLiquidity.init_pc_amount,
                    init_pc_amount : item.order?item?.initLiquidity.init_pc_amount : item?.initLiquidity.init_coin_amount,

                    isNew : true,
                }));

                console.log('new', formattedPair.length);
                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                    

                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);



   /*-------------- BUY POOL --------------*/

   const [clickedButtons, setClickedButtons] = useState({});
   const [clickedButtons_sell, setClickedButtons_sell] = useState({});

   const [tooltipVisible, setTooltipVisible] = useState(false);

   const [result, setResult] = useState<SwapResult | null>();

    const handleBuyToken = async(poolId : string) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        setResult(null);

        fetch(API_NEUTRON_BASE + '/buy/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);

          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }

    const handleSellToken = async(poolId : string) => {
        setClickedButtons_sell((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        setResult(null);

        fetch(API_NEUTRON_BASE + '/sell/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons_sell({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);

          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }
    

    return (
      <><Header/>
        <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} key="1">
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <TableContainer component={Paper} sx={{ overflowX: "auto", maxWidth: "100%" }}>
                                <Table>
                                  <TableHead>
                                        <TableRow sx={{textTransform: "uppercase"}}>
                                            <TableCell><AccessTimeIcon/></TableCell>
                                            <TableCell>Delay</TableCell>
                                            <TableCell>TX</TableCell>
                                            <TableCell>Init</TableCell>
                                            <TableCell>PAID</TableCell>
                                            <TableCell>Mint</TableCell>
                                            <TableCell>Pool</TableCell>
                                            <TableCell>Base</TableCell>
                                            <TableCell>Liquid</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>Owner</TableCell>

                                            <TableCell>API</TableCell>
                                            <TableCell>Link</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {dataList.map((item : any, index : number) => {
                                          const check_walletToken = walletTokenList.find(p => p.mint === item.mint );
                                          const check_balance = balanceList?.find(p => p?.address === getBaseVault(item.ammKeys));
                                          
                                          const check_complete = completeList.find(p => p.mint === item.mint );
                                          const user_address = check_complete?check_complete.user: null;
                    
                                          const check_dexpaid = dexpaidList?.find(p => p.tokenAddress === item.mint);
                    
                                          //LINKS
                                          const link_poolTx = 'https://solscan.io/tx/' + item.txId;
                                          const link_pool_by_poolId = `${API_DEXBULL_BASE}/pool/solana/${item.poolId}`;
                                          const link_token_by_mint = `${API_DEXBULL_BASE}/token/solana/${item.mint}`;
                                          const link_cap = `${API_DSOL_BASE}/mint/cap/${item.mint}`;
                                          const link_balance = 'https://api.neutron.ag/balance/sol/' + item.baseVault;

                                       return (
                                          <TableRow key={item.mint} sx={{
                                            backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                                            transition: "background-color 0.3s ease",
                                          }}>

                                              <TableCell>{varTime(Date.parse(item.createdAt) / 1000)}</TableCell>

                                              <TableCell>{formatTimestamp((Date.parse(item.createdAt) - item.creation_ts)/1000)}</TableCell>
                                              <TableCell>{item.txId?shortTokenLink(item.txId, TokenType.TX):''}</TableCell>

                                              <TableCell>{solNumber(item.init_coin_amount, true)}</TableCell>
                                              <TableCell>{check_dexpaid?(<div className='flex'>
                                                <img width="24" height="24" src={check_dexpaid.icon}/> <span className='ml-2 text-sm'>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"}</TableCell>
                                              
                                              <TableCell>{shortTokenLink(item.mint, TokenType.TOKEN)}
                                              </TableCell>

                                              <TableCell>{shortTokenLink(item.poolId, TokenType.ACCOUNT)}
                                              </TableCell>
                                              <TableCell>{shortTokenLink(getBaseVault(item.ammKeys), TokenType.ACCOUNT)}
                                              </TableCell>
                                              <TableCell>
                                                  {check_balance?solCap(check_balance.balance):'-'}
                                                  <div className='md:float-right max-sm:text-xs items-center whitespace-nowrap'>
                                                    {ratioChange(check_balance?.balance, item.init_coin_amount)}
                                                  </div>
                                              </TableCell>
                                              <TableCell></TableCell>

                                              
                                              <TableCell>
                                                <Box gap={1} display="flex">
                                                  {shortTokenLink(item.user, TokenType.ACCOUNT)}

                                                  {get_user_flow(item.mint, item.user)}

                                                </Box>
                                              </TableCell>

                                              <TableCell>
                                                <Box gap={1} display="flex">  
                                                    <a target="_blank" className="text-orange-500" href={link_token_by_mint}>TK</a>
                                                    <a target="_blank" className="text-orange-500" href={link_pool_by_poolId}>PL</a>
                                                    <a target="_blank" className="text-orange-500" href={link_cap}>CAP</a>
                                                    <a target="_blank" className="text-orange-500" href={link_balance}>LIQ</a>
                                                </Box>
                                              </TableCell>

                                              <TableCell>
                                                  {extLink(item.mint)}
                                              </TableCell>
                                             
                                              <TableCell>
                                                <Button variant="outlined" onClick={() => handleBuyToken(item.poolId)} className='bg-gray-500 px-2'>
                                                  {clickedButtons[item.poolId] ? 'BUY...' : 'BUY'}
                                                </Button>

                                                {
                                                  check_walletToken?(<Button variant="outlined"  onClick={() => handleSellToken(item.poolId)} className='bg-gray-500 px-2'>
                                                    {clickedButtons_sell[item.poolId] ? 'SELL...' : 'SELL'}
                                                  </Button>):''
                                                }


                                                  

                                              </TableCell>
                                             

                                          </TableRow>
                                        )})
                                      }

                                      </TableBody>
                                    </Table>
                              </TableContainer>
                        </Card>
                    </Grid>
                </Grid>
        </Box>

        {/* <Box sx={{ p: 0 }}>
                <Card sx={{ p: 2, width: "100%", maxWidth: "100%" }}>
                    <CardContent>
                        <Typography variant="h6">By Day</Typography>
                        <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={countList}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="_id" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
                        </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>   
          </Box> */}

        {/* Tooltip cố định ở bottom */}
        {tooltipVisible && (
          <div
            data-tooltip-id="bottom-tooltip"
            className="fixed bottom-4 left-0 w-full flex justify-center"
          >
            <div className="bg-gray-800 text-white px-4 py-2 rounded shadow-lg">
              <p>
              <strong>Status:</strong>{" "}
              <span className="text-green-400">{result?.status}</span>
              </p>

              <p>
                <strong>Mint:</strong>
                <br />
                <code>{result?.mint}</code>
              </p>

              <p>
                <strong>Signature:</strong>
                <br />
                <code>{result?.signature}</code>
              </p>
              
              <a
                href={result?.url!}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline"
              >
                View on Solscan
              </a>
            </div>
          </div>
        )}

      </>
    )
  
}
  
export default PairD;
