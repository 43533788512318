import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {shortTokenLink, varTime,  pumpLink, dexscreenerLink, get_user_flow, TokenType, tokencheckLink, dexbullBot, extLink} from '../components/Helper';
import { useCountList } from 'src/components/Utils';
import { Box, Button, Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import { API_DSOL_BASE, API_NEUTRON_BASE } from 'src/config';


interface TokenComplete {
  timestamp: number;
  delay: number;
  mint : string;
  bondingCurve: string;
  user: string;
  isNew : boolean,
}

const PumpComplete : React.FC = () => {

  const refs = {
      complete: useRef(true),
      walletToken: useRef(true),
      balance: useRef(true),
      dexpaid : useRef(true),
  };

   const pairURL = "wss://ws.neutron.ag/pump/complete"; //8002

   const [dataList, setDataList] = useState<TokenComplete[]>([]);
   const countList = useCountList(API_NEUTRON_BASE + "/pump/day/complete");

   //#region : LOAD DEXPAID 
   const [dexpaidList, setDexpaidList] = useState<DexpaidOrder[]>([]);
   useEffect(() => {
      if(refs.dexpaid.current === true){
        fetch('https://api-paid.dexbull.com/chain/solana?limit=50')
          .then(response => {
            return response.json(); 
          })
          .then(data => {
              setDexpaidList(data);
              refs.dexpaid.current = false; // Reset cờ sau khi xử lý xong
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
      }
   },[dataList]);
   //#endregion


   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: TokenComplete[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    delay: item.delay,
                    mint : item.mint,
                    bondingCurve: item.bondingCurve,
                    user: item.user,
                  
                    isNew : false,
                }));


                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                if(message.data.length > 0) {
                 
                  refs.dexpaid.current = true;
                }
                
                const formattedPair: TokenComplete[] = message.data.map((item: any) => ({
                  timestamp: item.timestamp,
                  delay: item.delay,
                  mint : item.mint,
                  bondingCurve: item.bondingCurve,
                  user: item.user,

                  isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);



    return (
      <><Header/>
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} key="1">
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <TableContainer component={Paper} sx={{ overflowX: "auto", maxWidth: "100%" }}>
                            <Table>
                              <TableHead>
                                    <TableRow sx={{textTransform: "uppercase"}}>
                                        <TableCell><AccessTimeIcon/></TableCell>
                                        <TableCell>Delay</TableCell>
                                        
                                        <TableCell>PAID</TableCell>
                                        <TableCell>Mint</TableCell>
                                        <TableCell>Curve</TableCell>
                                        
                                        <TableCell>Owner</TableCell>
                                        <TableCell>LINK</TableCell>
                                        <TableCell>API</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                  {dataList.map((item : any, index : number) => {
                                      const check_dexpaid = dexpaidList?.find(p => p.tokenAddress === item.mint);
                                      const link_cap = `${API_DSOL_BASE}/mint/cap/${item.mint}`;
                                    return (
                                      <TableRow key={item.mint}>

                                          <TableCell>{varTime(item.timestamp /1000)}</TableCell>
                                          <TableCell>{(item.delay/1000).toFixed(1)}s</TableCell>
                                          <TableCell>{check_dexpaid?(<div className='flex'><img width="24" height="24" src={check_dexpaid.icon}/> <span className='ml-2 text-sm'>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"} </TableCell>
                                          <TableCell>{shortTokenLink(item.mint, TokenType.TOKEN)}</TableCell>
                                          <TableCell>{shortTokenLink(item.bondingCurve, TokenType.ACCOUNT)}</TableCell>
                                          
                                          
                                          <TableCell>
                                            <Box gap={2} display="flex">
                                              {shortTokenLink(item.user, TokenType.ACCOUNT)}

                                              {get_user_flow(item.mint, item.user)}

                                            </Box>
                                          </TableCell>

                                          <TableCell>
                                              {extLink(item.mint)}
                                          </TableCell>

                                          <TableCell>
                                              <a target="_blank" className="text-orange-500"  href={link_cap}>CAP</a>
                                          </TableCell>
                                          

                                      </TableRow>
                                    )})
                                  }

                                  </TableBody>
                                </Table>
                          </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ p: 0 }}>
              <Card sx={{ p: 2, width: "100%", maxWidth: "100%" }}>
                  <CardContent>
                      <Typography variant="h6">By Day</Typography>
                      <ResponsiveContainer width="100%" height={200}>
                      <LineChart data={countList}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="_id" />
                          {/* <XAxis dataKey="_id" tickFormatter={formatTime} /> */}
                          <YAxis />
                          <Tooltip />
                          {/* <Tooltip labelFormatter={formatTime} /> */}
                          <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
                      </LineChart>
                      </ResponsiveContainer>
                  </CardContent>
              </Card>   
        </Box>
      </>
    )
  
}
  
export default PumpComplete;