// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );

// reportWebVitals();

import ReactDOM from 'react-dom/client';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#181b21',
      light: '#42a5f5',
    },
    secondary: {
      main: '#fcd535',
      light: '#42a5f5',
    },
  },
  typography: {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    // <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    // </BrowserRouter>
  // </React.StrictMode>
);
