import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {shortTokenLink, varTime, get_user_flow, TokenType, solCap, extLink} from '../components/Helper';
import { useBalanceList, useBalanceList2, useCompleteList, useCountList, useDexpaidList } from 'src/components/Utils';

import { Box, Button, Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import { API_NEUTRON_BASE } from 'src/config';



interface TokenKing {
  timestamp: number;
  delay: number;
  mint : string;
  bonding_curve: string;
  associated_bonding_curve: string;
  usd_market_cap : number;
  complete : number,

  isNew : boolean,
}


const PumpKing : React.FC = () => {
  const refs = {
      complete: useRef(true),
      walletToken: useRef(true),
      balance: useRef(true),
      dexpaid : useRef(true),
  };

   const pairURL = "wss://ws.neutron.ag/pump/king"; //8002

   const [dataList, setDataList] = useState<TokenKing[]>([]);

   const completeList = useCompleteList(dataList, refs);
   const balanceList = useBalanceList2(dataList, refs, "bonding_curve");
   const dexpaidList = useDexpaidList(dataList, refs);

   const countList = useCountList(API_NEUTRON_BASE + "/pump/day/king");

   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                

                const formattedPair: TokenKing[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    delay: item.delay,
                    mint : item.mint,
                    bonding_curve: item.bonding_curve,
                    associated_bonding_curve: item.associated_bonding_curve,
                    usd_market_cap : item.usd_market_cap,
                    complete : item.complete,
                    isNew : false,
                }));


                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                if(message.data.length > 0) {
                  refs.balance.current = true;
                  refs.complete.current = true;
                  refs.dexpaid.current = true;
                }

                const formattedPair: TokenKing[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    delay: item.delay,
                    mint : item.mint,
                    bonding_curve: item.bonding_curve,
                    associated_bonding_curve: item.associated_bonding_curve,
                    usd_market_cap : item.usd_market_cap,
                    complete : item.complete,
                    isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);

    /*-------------- BUY PUMP --------------*/

    const [clickedButtons, setClickedButtons] = useState({});
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [result, setResult] = useState<SwapResult | null>();

    const handleBuyToken = async(poolId) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        //alert(`Button clicked for item with id: ${poolId}`);
        fetch(API_NEUTRON_BASE + '/buy/pump/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }


    return (
      <><Header/>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} key="1">
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <TableContainer component={Paper} sx={{ overflowX: "auto", maxWidth: "100%" }}>
                            <Table>
                              <TableHead>
                                    <TableRow sx={{textTransform: "uppercase"}}>
                                        <TableCell><AccessTimeIcon/></TableCell>
                                        <TableCell>Delay</TableCell>
                                        
                                        <TableCell>PAID</TableCell>
                                        <TableCell>Mint</TableCell>
                                        <TableCell>Curve</TableCell>
                                        <TableCell>1st King</TableCell>
                                        <TableCell>Liquid</TableCell>
                                        
                                        <TableCell>Owner</TableCell>
                                        <TableCell>LINK</TableCell>
                                        <TableCell>API</TableCell>
                                        <TableCell>Action</TableCell>

                                      
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {dataList?.slice(0, 20).map((item, index) => {
                                    const check_dexpaid = dexpaidList?.find(p => p.tokenAddress === item.mint);
                                    const check_balance = balanceList?.find(p => p.address === item.bonding_curve);
                                    const check_complete = completeList.find(p => p.mint === item.mint );
                                    const user_address = check_complete?check_complete.user: null;

                                    const link_cap = 'https://api.neutron.ag/pump/cap/' + item.mint;
                                    const link_curve = 'https://api.neutron.ag/pump/curve/' + item.mint;
                                    const link_balance = 'https://api.neutron.ag/balance/sol/' + item.bonding_curve;

                                    return (
                                      <TableRow key={item.mint} sx={{
                                          backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                                          transition: "background-color 0.3s ease",
                                        }}>

                                          <TableCell>{varTime(item.timestamp /1000)}</TableCell>
                                          <TableCell>{(item.delay/1000).toFixed(1)}s</TableCell>
                                          <TableCell>{check_dexpaid?(<div className='flex'><img width="24" height="24" src={check_dexpaid.icon}/> <span className='ml-2 text-sm'>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"} </TableCell>
                                          <TableCell>{shortTokenLink(item.mint, TokenType.TOKEN)}</TableCell>
                                          <TableCell>{shortTokenLink(item.bonding_curve, TokenType.ACCOUNT)}</TableCell>
                                          
                                          <TableCell>${Number(item.usd_market_cap).toLocaleString('en-US')} <span className='text-gray-500 float-right'>{item.complete}%</span></TableCell>

                                          <TableCell>{check_balance?solCap(check_balance.balance):'-'}</TableCell>
                                          <TableCell>
                                            <Box gap={2} display="flex">
                                              {user_address?(shortTokenLink(user_address, TokenType.ACCOUNT)):''}
                                              {get_user_flow(item.mint, user_address)}

                                           

                                            </Box>
                                          </TableCell>

                                          <TableCell>
                                              {extLink(item.mint)}
                                          </TableCell>

                                          <TableCell>
                                              <a target="_blank" className="text-orange-500"  href={link_cap}>CAP</a>
                                          </TableCell>
                                          <TableCell>
                                            <Button variant="outlined" onClick={() => handleBuyToken(item.mint)} className='bg-gray-500 px-2'>
                                              {clickedButtons[item.mint] ? 'BUY...' : 'BUY'}
                                            </Button>
                                          </TableCell>
                                          

                                      </TableRow>
                                    )})
                                  }

                                  </TableBody>
                                </Table>
                          </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        

        <Box sx={{ p: 0 }}>
              <Card sx={{ p: 2, width: "100%", maxWidth: "100%" }}>
                  <CardContent>
                      <Typography variant="h6">By Day</Typography>
                      <ResponsiveContainer width="100%" height={200}>
                      <LineChart data={countList}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="_id" />
                          {/* <XAxis dataKey="_id" tickFormatter={formatTime} /> */}
                          <YAxis />
                          <Tooltip />
                          {/* <Tooltip labelFormatter={formatTime} /> */}
                          <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
                      </LineChart>
                      </ResponsiveContainer>
                  </CardContent>
              </Card>   
        </Box>

        {/* Tooltip cố định ở bottom */}
        {tooltipVisible && (
          <div
            data-tooltip-id="bottom-tooltip"
            className="fixed bottom-4 left-0 w-full flex justify-center"
          >
            <div className="bg-gray-800 text-white px-4 py-2 rounded shadow-lg">
              <p>
              <strong>Status:</strong>{" "}
              <span className="text-green-400">{result?.status}</span>
              </p>

              <p>
                <strong>Mint:</strong>
                <br />
                <code>{result?.mint}</code>
              </p>

              <p>
                <strong>Signature:</strong>
                <br />
                <code>{result?.signature}</code>
              </p>
              
              <a
                href={result?.url!}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline"
              >
                View on Solscan
              </a>
            </div>
          </div>
        )}
      </>
    )
  
}
  
export default PumpKing;