import { Helmet } from 'react-helmet';
import Logo from '../neutron-logo.png';
import React, { useState, useEffect } from 'react';

function Home(){
    const [isRotated, setRotated] = useState(false);

    const handleClick =() => {
        setRotated(!isRotated);
    }

    return (
      <>
        <Helmet>
            <link rel="icon" href={Logo} />
            <link rel="apple-touch-icon" href={Logo} />
            <style>
                {
                    `@font-face {
                        font-family: "Nulshock";
                        src: url("/fonts/nulshock_bd.otf") format("truetype");
                        font-weight: normal;
                        font-style: normal;
                    }
                    
                    body {
                        font-family: "Nulshock", sans-serif;
                        background-color : #0a1520;
                        height: 100vh;

                        background-repeat: no-repeat;
                        background-position: top;
                    }
                    
                    .color-main {
                        color : #31d99c;
                    }
                    `
                }
            </style>
        </Helmet>
        <main className="h-full">
            <div className="w-full text-white text-center">
                <div className="text-5xl mt-20">NEUTRON</div>
                <div className="text-xl color-main">Aggregator</div>
                <div className="text-center mt-10">
                    <img  alt="neutron"
                        className={`w-60 max-sm:100 mx-auto  ${isRotated ? 'animate-spin animate-spin-logo' : 'animate-pulse'}`}
                        onClick={handleClick}
                        src="/neutron-logo.png" />
                </div>
            </div>
        </main>
      </>
    )
}
  
export default Home