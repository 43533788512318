import Header from '../components/header/Header';
import React, { useState, useEffect,} from 'react';
import {shortTokenLink, varTime , formatToken, TokenType, extLink} from '../components/Helper';
import { Box, Button, Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { API_NEUTRON_BASE } from 'src/config';


const Swap : React.FC = () => {

   const pairURL = "wss://ws.neutron.ag/swaps"; //8002

   const [dataList, setDataList] = useState<Swap[]>([]);

   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: Swap[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    duration : item.duration,
                    direction: item.direction,
                    mint : item.mint,
                    poolId : item.poolId,
                    wallet: item.wallet,
                    tx: item.tx,
                    solAmount : item.solAmount,
                    splAmount : item.splAmount,
                    PnL : item.PnL,
                    mode : item.mode,

                    isNew : false,
                }));

              
                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                const formattedPair: Swap[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    duration : item.duration,
                    direction: item.direction,
                    mint : item.mint,
                    poolId : item.poolId,
                    wallet: item.wallet,
                    tx: item.tx,
                    solAmount : item.solAmount,
                    splAmount : item.splAmount,
                    PnL : item.PnL,
                    mode : item.mode,

                    isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);


   /*-------------- SELL POOL --------------*/

    const [clickedButtons, setClickedButtons] = useState({});


    const handleSellToken = async(poolId) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        fetch(API_NEUTRON_BASE + '/sell/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }


    return (
      <><Header/>
      <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} key="2">
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <TableContainer component={Paper} sx={{ overflowX: "auto", maxWidth: "100%" }}>
                          <Table>
                              <TableHead>
                                    <TableRow sx={{textTransform: "uppercase"}}>
                                        <TableCell><AccessTimeIcon/></TableCell>
                                        <TableCell>Tx</TableCell>
                                        
                                        <TableCell>Type</TableCell>
                                        <TableCell>Mode</TableCell>
                                        <TableCell>Mint</TableCell>
                                        <TableCell>PoolId</TableCell>
                                        <TableCell><ShareOutlinedIcon/></TableCell>
                                        <TableCell>Wallet</TableCell>
                                        

                                        <TableCell>SOL</TableCell>
                                        <TableCell>SPL</TableCell>
                                        <TableCell>API</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {dataList.map((item : any, index : number) => (
                                    <TableRow key={item.mint}>

                                        <TableCell>
                                          {varTime(item.timestamp /1000)}
                                        </TableCell>

                                        <TableCell>
                                          {shortTokenLink(item.tx, TokenType.TX)}
                                        </TableCell>

                                        <TableCell>
                                          {item.direction}
                                        </TableCell>
                                        <TableCell>{item.mode}</TableCell>
                                        <TableCell>{shortTokenLink(item.mint, TokenType.TOKEN)}</TableCell>
                                        <TableCell>{shortTokenLink(item.poolId, TokenType.ACCOUNT)}</TableCell>
                                        <TableCell>
                                            {extLink(item.mint)}
                                        </TableCell>
                                        <TableCell>{shortTokenLink(item.wallet, TokenType.ACCOUNT)}</TableCell>
                                        <TableCell>{Number(item.solAmount) /10 ** 9}</TableCell>
                                        <TableCell>{formatToken((Number(item.splAmount) /10 ** 6).toFixed(0))}</TableCell>

                                        <TableCell>
                                          <Button variant="outlined" onClick={() => handleSellToken(item.poolId)}>
                                            {clickedButtons[item.poolId] ? 'SELL...' : 'SELL'}
                                          </Button>
                                        </TableCell>
                                        <TableCell>
                                          {item.duration / 1000}s
                                        </TableCell>

                                    </TableRow>
                                  ))
                                }

                                </TableBody>
                          </Table>
                      </TableContainer>
                  </Card>
              </Grid>
          </Grid>

      </Box>
      </>
    )
  
}
  
export default Swap;